import React from 'react';
import { useRouter } from 'next/router';

import {
  useAuth,
  VIEWS,
} from 'providers/authentication/authentication.provider';
import { AuthLoading } from 'layouts/auth/auth-loading.component';
import { AuthLayout } from 'layouts/auth/auth-page.component';

const Home = () => {
  const router = useRouter();
  const { initial, view, user } = useAuth();

  if (initial) return <AuthLoading />;

  if (router.pathname === 'reset-password') {
    return <AuthLayout view={VIEWS.UPDATE_PASSWORD} />;
  } else if (user) {
    router.push('/jobs');
    return <AuthLoading />;
  }

  return <AuthLayout view={view} />;
};

export default Home;
