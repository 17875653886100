import React from 'react';
import settings from 'config/settings/settings.config';

import { Header } from 'containers/header/header.component';

import styles from './auth.module.css';

export const AuthLoading = () => (
  <div>
    <Header title={settings.title} />
    <div className={styles.container}>
      <div className={styles.inner}>
        <p className={styles.loading}>Loading...</p>
      </div>
    </div>
  </div>
);
